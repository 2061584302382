import React, {useState, useEffect} from "react"
import { graphql } from 'gatsby';
import { gsap } from "gsap"
import useViewport from '../utils/useViewport'
import LayoutInner from "../components/LayoutInner"
import Seo from "../components/Seo"
import Section from "../components/Section";
import Cover from "../components/Cover";
import Svg from "../components/SVG.js";
import { useData } from "../utils/DataProvider";

const ChaptersPage = ({data, location}) => {
  const [state, setState] = useData();
  const {width} =  useViewport();
  const isMobile = width < 768;
  const chaptersData = data.chaptersQuery;
  const sections = chaptersData.edges[0].node.sections;

  const [chap, setChap] = useState({
    active: false,
    color: null,
    title: null,
    content: null
  });

  const handleTitle = (item) => {
    setChap({
      active: true,
      color: item.color,
      title: item.title,
      content: item.content.content
    });
    isMobile && openChapter(true)
  };

  const handleMouseLeave = () => {
    setChap({
      active: false,
      color: null,
      title: null,
      content: null
    });
    isMobile && openChapter(false)
  };

 const openChapter = (action) => {
  setState({ ...state, isOverlayOpen: action});
}


  const chaptersAnimation = () => {
    gsap.from('.jsChaptersTitle', { opacity: 0, y: 30, duration: 1 })
    isMobile && gsap.from('.jsChapterTitle', { opacity: 0, y: 30, duration: 1, delay: 0.5 });
    gsap.from('.jsChapters li', {
      duration: 1.5,
      opacity: 0,
      delay: 0.5,
      y: 30,
      stagger: 0.1,
      ease: "power4.inOut",
    });
    gsap.from('.chaptersContent', {
      duration: .5,
      opacity:0,
      delay: 1,
      x: 20,
    })
    gsap.from('.jsHeader', { opacity: 0, duration: 0.5, delay: 1, x: 20 })
    gsap.from('.jsFooter', { opacity: 0, duration: 1, delay: 3 })
  }

  useEffect(() => {
    chaptersAnimation();
  }, [])


  return (
    <LayoutInner location={location} >
      <Seo title={chaptersData.title} />
      <div className="pageWrapper">
        {!isMobile && (
          <div className="pageCover">
            <Cover />
            {/* Overlay for desktop */}
            <div
              data-active={chap.active}
              className="chapterOverlay"
              style={{ '--chapter-bgr': `var(--color-${chap.color})` }}>
              <div>
                <span>{chap.content}</span>
              </div>
            </div>
          </div>
        )}
        <div className="pageContent chaptersContent">
          {sections.map((section, index) => (
            <Section
              key={index}
              color={section.color}
              className={`inner section-${index+1}`} >
              <div className="chapters-wrap">
                <h2 className="chapters-title jsChaptersTitle">{section.name}</h2>
                <h3
                  className="chapter-title jsChapterTitle"
                  data-active={chap.active}
                  style={{ 'color': `var(--color-${!isMobile && chap.color})` }}
                  >
                  <span>{isMobile ? 'Click icons to see details' : chap.title  }</span>
                </h3>
                {section.chapters && (
                  <ul className="chapters jsChapters">
                    {section.chapters.map((chapter, index) => (
                      <li key={index}>
                        <span
                          title={chapter.title}
                          data-color={chapter.color}
                          className="chapter"
                          role="button"
                          tabIndex={0}
                          onMouseOver={() => handleTitle(chapter)}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => isMobile && handleTitle(chapter)}
                          onFocus={() => handleTitle(chapter)}
                          >
                          <img
                            src={chapter.image.file.url}
                            alt={chapter.title}
                            />
                        </span>
                      </li>
                    ))}
                  </ul>
                  )
                }
              </div>
            </Section>
          ))}
        </div>
        {/* Overlay for mobile */}
        {isMobile && (
          <div
            data-active={chap.active}
            className="chapterOverlay"
            style={{ '--chapter-bgr': `var(--color-${chap.color})` }}>
            <button
              className="closeChapter"
              onClick={() => handleMouseLeave()}>
              <Svg size="0 0 13 22" icon="chevron" />
            </button>
            <div>
              <h2>{chap.title}</h2>
              <span>{chap.content}</span>
            </div>
          </div>
        )}
      </div>

    </LayoutInner>
  )
}

export default ChaptersPage

export const query = graphql`
  query Chapters {
    chaptersQuery: allContentfulPage(filter: { contentful_id: { eq: "7ADyFpa23mlYEnVkcdqZ6t" } }) {
      edges {
        node {
          contentful_id
          keywords
          title
          sections {
            color
            name
            chapters {
              title
              content {
                content
              }
              color
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

